@import "../../scss/variables";

.lang-switcher-container {
  .MuiInputBase-root.MuiOutlinedInput-root {
    .MuiSelect-select {
      font-size: 16px !important;
      color: $text-dark;
      font-family: "Urbanist-Regular" !important;
      min-width: 160px;
      min-height: auto;
      max-height: 1.2rem;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .icon-switcher {
    cursor: pointer;
    font-size: 24px;
  }
}

.MuiList-root.MuiMenu-list {
  box-shadow: 0 5px 10px rgba(160, 163, 189, 0.15);

  li.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: $white !important;
  }
}

html[dir="rtl"] {
  .lang-switcher-container {
    .MuiSvgIcon-root.MuiSelect-icon {
      right: auto;
      left: 2rem;
    }
  }
}
