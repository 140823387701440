@import "../../../../scss/variables";

.title-wrapper {
  display: flex;
  align-items: center;

  span {
    width: 13px;
    height: 25px;
    border-radius: 4px;
    background-color: $blue-title-mark;
    margin-inline-end: 1rem;
  }

  h2 {
    font-size: 1.4rem !important;
    color: $dark-gray;
    @media #{$media-sm} {
      font-size: 1.2rem !important;
    }
  }
}

.label-color {
  color: $slate-grey !important;
}

.language-skills {
  border-bottom: 1px solid $gray-border;
  margin-bottom: 1rem;
  .proficiency-text {
    padding-bottom: 1rem;
  }
}

.badge-content {
  color: $dark-gray !important;
  background-color: $gray-light !important;
  border: 1px solid $gray-stepper-disabled !important;
}