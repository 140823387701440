@import "../../../scss/variables";

.filter-toggle-btn {
  .filter-btn {
    letter-spacing: 7px;
    .MuiSvgIcon-root {
      width: 20px;
    }
  }
}

html[dir="rtl"] {
  .filter-toggle-btn {
    .filter-btn {
      letter-spacing: 0px;
      span.label {
        font-size: 1.1rem;
      }
    }
  }
}
