@import "../../scss/variables";

.MuiDataGrid-root {
  border-radius: 1rem !important;
  border: none !important;
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding: 0 1.2rem !important;
  }
  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-iconSeparator {
    display: none;
  }
  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeaders {
    border-bottom: none !important;
  }
  .MuiDataGrid-cell:focus {
    outline: none !important;
  }
  .MuiDataGrid-row:hover {
    background-color: initial !important;
  }
  .MuiDataGrid-row:nth-child(odd),
  .Mui-selected:nth-child(odd) {
    background-color: $gray-border !important;
    color: $text-dark;
  }
  .MuiDataGrid-row.row-hover:hover {
    cursor: pointer;
    background-color: $table-hover-bg !important;
  }
  .MuiDataGrid-columnHeader {
    .MuiDataGrid-columnHeaderTitleContainer {
      font-weight: 500;
      font-size: 0.875rem;
      color: $gray-icon;
    }
  }
  .MuiDataGrid-cell {
    text-align: center;
    font-size: 0.93rem;
  }
  .MuiDataGrid-virtualScroller {
    overflow: auto !important;
  }
  .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }
  .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    height: 7px !important;
    background: transparent;
  }

  .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }
  .MuiDataGrid-virtualScroller::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-right: 20px;
  }

  .MuiDataGrid-virtualScroller::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-left: 20px;
  }
}

.MuiDataGrid-panelContent::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
.MuiDataGrid-panelContent::-webkit-scrollbar {
  width: 5px !important;
  background: transparent;
}

.MuiDataGrid-panelContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}
.MuiDataGrid-panelContent::-webkit-scrollbar-track-piece:end {
  background-color: transparent;
  margin-right: 20px;
}

.MuiDataGrid-panelContent::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-left: 20px;
}

html[dir="rtl"] {
  .MuiDataGrid-root.MuiDataGrid-root--densityStandard {
    direction: ltr !important;
    transform: scale(-1, 1);

    .MuiDataGrid-columnHeaderTitle {
      transform: scale(-1, 1);
    }

    .MuiDataGrid-cellContent {
      transform: scale(-1, 1);
    }

    .MuiDataGrid-footerContainer {
      transform: scale(-1, 1);
    }
  }
}
