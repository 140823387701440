@import "../../scss/variables";

.table-container {
  padding: 0.5rem 0 !important;
  text-align: center !important;

  th {
    padding: 0.6rem 1.2rem;
    border-bottom: none !important;
    font-weight: 500;
    font-size: 0.75rem;
    color: $gray-icon;
  }

  td {
    border-bottom: none !important;
    padding: 0.6rem 1.2rem;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $gray-light-border;
      color: #fff;
    }

    td {
      font-size: 1rem;
      color: $text-dark;
    }
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

html[dir="rtl"] {
  .table-container {
    .MuiTableCell-root {
      text-align: start;
      direction: rtl;
    }
  }
}
