@import "../../scss/variables";

.notifications-page {
  color: $slate-grey;
  
  .content-wrapper {
    background-color: #fff;
    padding: 2.14rem;
    margin: 0 !important;
  }
  .notifications-list {
    .notification-item {
      position: relative;
      &:hover {
        background-color: #f6f6f6;
      }
      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
  .show-more-btn-wrapper {
    margin-top: 1.5rem;
  }
  .adorment-notifaction {
    .seen-adorment {
      font-size: 1rem;
    }
    position: absolute;
    left: 96%;
    top: 20px;
    color: #448bca;
  }
  .show-more-btn,
  .show-more-btn:hover {
    color: $text-main-blue;
    border-color: $text-main-blue;
  }
}
html[dir="rtl"] {
  .adorment-notifaction {
    right: 96%;
  }
}
