@import "../../scss/variables";

.notifications-menu::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

.notifications-menu::-webkit-scrollbar {
  width: 7px;
  background: transparent;
}

.notifications-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.notifications-menu::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 20px;
}

.notifications-menu::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 20px;
}
