@import "../../scss/variables";

.pagination-wrapper {
  .MuiPaginationItem-page.Mui-selected,
  .MuiPaginationItem-page.Mui-selected:hover {
    font-weight: 700;
    background-color: $text-main-blue;
    color: $white;
    border-radius: 0;
  }

  .MuiPaginationItem-page,.MuiPaginationItem-page:hover{
    border-radius: 0;
    background-color: transparent;
    font-weight: 400;
    color: $gray;
  }
  .MuiPaginationItem-root {
    margin: 0 !important;
  }
}
