@import "../../../scss/_variables.scss";

.login-page {
  .welcome-title {
    font-size: 2.5rem;
    font-family: "Urbanist-Bold" !important;
    color: $text-main-blue;
  }

  .box-contaienr {
    border: 1.2px solid $border-color;
  }

  .form-login {
    width: 100%;
  }

  .orange-label {
    @media #{$media-desktop-max-lg} {
      margin: 0px !important;
      align-self: flex-end;
    }
  }

  .MuiIconButton-edgeEnd {
    margin-right: unset !important;
  }

  .flex-sm-column {
    @media #{$media-desktop-max-lg} {
      flex-direction: column-reverse;
    }
  }

  .check-box-container div:first-of-type {
    @media #{$media-desktop-max-lg} {
      align-self: flex-start !important;
    }
  }

  input.MuiOutlinedInput-input {
    border-radius: 0;
    background-color: $gray-mid;
    font-size: 18px;
    font-family: "Urbanist-Regular" !important;
  }

  .input-wrapper .MuiInputBase-root {
    border-radius: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
