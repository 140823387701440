@import "../../scss/variables";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.input-wrapper {
  label.MuiFormLabel-root {
    color: $gray-label-color;
    margin: 0 0 0.5rem 0 !important;
  }

  .MuiFilledInput-root {
    border-radius: 16px;
    background-color: $gray-mid;
    font-size: 1rem;
    border-radius: 0;

    &:focus {
      border: 1px solid $text-main-blue;
    }

  }

  input.MuiInputBase-input {
    padding: 0.8rem;
  }

  input.MuiInputBase-input::placeholder {
    color: $gray-placeholder-darker !important;
    font-size: 1rem;
  }

  input.MuiInputBase-input.MuiFilledInput-input::placeholder {
    font-size: 1rem !important;
  }

  textarea.MuiInputBase-inputMultiline::placeholder {
    color: $gray-placeholder-darker !important;
  }

  .MuiFilledInput-root:hover {
    background-color: $gray-light;
  }

  input.MuiInputBase-input:focus::placeholder {
    color: $gray;
  }

  input.MuiInputBase-input.MuiFilledInput-input.Mui-disabled {
    -webkit-text-fill-color: $disabled-text;
    background-color: $gray-mid;
  }

  .icon-space,
  .MuiInputAdornment-root {
    margin-right: 0;
    margin-inline-start: 1.5rem;
    color: $gray-placeholder-darker;
  }

  input[type="password"] {
    font-family: Verdana;
  }
}