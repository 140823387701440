@import "../../scss/variables";

.radio-btns-wrapper {
  label.MuiFormLabel-root {
    color: $gray-label-color;
  }
  span.MuiButtonBase-root.MuiRadio-root {
    color: $text-main-blue;
  }
  span.MuiTypography-root {
    font-size: 14px;
    color: $gray-placeholder-darker;
  }
}
