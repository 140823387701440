@import "../../scss/variables";

.notifications-support-alert-wrapper {
  position: relative;
  .notifications-support-alert {
    border-radius: 15px;
    display: flex;
    align-items: center;
  }
  .bg-shape {
    position: absolute;
    bottom: 0;
  }
  .info-icon {
    position: absolute;
    top: -35%;
    left: 2%;
    @media #{$media-desktop-lg} {
      top: -25%;
    }
    @media #{$media-mobile-sm} {
      top: -15%;
    }
  }
  .MuiAlert-message {
    font-size: 1rem;
  }
  .MuiAlert-icon {
    margin-right: 0.75rem;
  }
}

html[dir="rtl"] {
  .notifications-support-alert-wrapper {
    .info-icon {
      left: auto;
      right: 2%;
    }
    .bg-shape {
      transform: rotateY(180deg);
    }
    .MuiAlert-icon {
      margin-left: 0.75rem;
      margin-right: 0;
    }
    .MuiAlert-action {
      margin-right: auto;
      margin-left: -8px;
    }
  }
}
