.avatar-container {
  &.small-avatar {
    .MuiAvatar-root {
      font-size: 1rem;
    }
  }
  &.large-avatar {
    .MuiAvatar-root {
      font-size: 2rem;
    }
  }
}
