@import "../../scss/variables";

.selectWrapper {
    label {
        color: $gray-label-color !important;
        margin: 0 0 0.5rem 0 !important;
    }

    input {
        background-color: $gray-mid;
        border: none !important;
        padding: 0.8rem !important;
        width: 100% !important;
        font-family: "Roboto" !important;
    }

    input::placeholder {
        font-family: "Roboto" !important;
        font-size: 1rem !important;
        color: $gray-placeholder-darker;
    }

    input:focus {
        outline: none !important;
    }

    input:hover {
        background-color: $gray-mid;
    }

    input:focus::placeholder {
        color: $gray-placeholder-darker;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
        padding-right: 0 !important;
        outline: none !important;
    }

    .MuiAutocomplete-inputRoot {
        flex-wrap: nowrap !important;
        padding-right: 0 !important;
        border: none !important;
    }

    .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
        padding: 0.8rem;
    }

    .MuiInput-underline:after,
    .MuiInput-underline:before {
        content: none !important;
    }

    .MuiAutocomplete-endAdornment {
        right: 1rem;
    }

    .MuiAutocomplete-popupIndicator,
    .MuiAutocomplete-popupIndicator:hover {
        color: $gray-icon;
    }

    .MuiInputBase-root.Mui-disabled {

        .MuiAutocomplete-endAdornment {
            right: 3rem !important;
        }

        input {
            -webkit-text-fill-color: $disabled-text;
        }
    }

    .chevron-down {
        position: absolute;
        inset-inline-end: 0.938rem;
        color: $gray-icon;
        pointer-events: none;
    }
}

.chevron-down {
    position: absolute;
    inset-inline-end: 0.938rem;
    color: $gray-icon;
    pointer-events: none;
}