@import "../../scss/variables";

.add-employee-page {
  .addProjectManager-page-container {
    background-color: $white;
    padding: 2rem;
    .addProjectManager-input {
      margin-bottom: 1rem !important;
    }
  }
  button.continue-btn,
  button.cancel-btn {
    max-width: 5.1875rem;
    max-height: 3rem !important;
    &.Mui-disabled {
      span {
        font-size: 1rem;
      }
    }
    span {
      font-size: 1rem;
    }
  }
  button.continue-btn {
    span {
      color: $white;
    }
  }
  button.cancel-btn {
    border-color: $black !important;
    span {
      color: $black;
    }
    &:hover {
      background-color: transparent !important;
    }
  }

  .employee-upload-wrapper {
    min-width: 15rem;
    max-width: 20%;
  }

  .employee-image-upload-initial-container {
    width: 100% !important;
    background-color: $gray-mid !important;
    aspect-ratio: 1/1;
  }

  .center-uplod-btn {
    background-color: $text-main-blue;
    padding: 0.32rem;
    font-size: 1rem !important;
    border-radius: 4px;
  }

  .min-height-image {
    min-height: 6rem;
  }

  .employee-image-upload-container {
    z-index: 2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    aspect-ratio: 1/1;
    height: 100%;
  }
}

.add-employee-btn-container {
  button {
    font-family: "Roboto" !important;
  }
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  @media #{$media-sm} {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.employees-list-container {
  .phone-number-cell {
    min-width: 10rem;
  }
}

html[dir="rtl"] {
  .phone-number-cell {
    direction: ltr !important;
    text-align: end !important;
  }
}

.employee-details-page-container {
  .btn-container {
    .reset-btn {
      min-width: 8rem !important;
    }
    .btn {
      width: max-content;
      @media #{$media-sm} {
        margin-top: 0.5rem;
        min-width: 6rem;
        max-width: 6rem;
      }
    }
    @media #{$media-mobile} {
      width: 100%;
    }
  }
}
