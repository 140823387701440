@import "../../../scss//variables";

.applicant-details-page-container {
  .btn-container {
    @media #{$media-mobile} {
      margin-top: 1rem;
    }
  }
}

.profile-img-popup-modal {
  .MuiPaper-root {
    padding: 0.5rem !important;
    width: 35% !important;
    @media #{$media-mobile} {
      width: 55% !important;
    }
    @media #{$media-mobile-sm} {
      width: 70% !important;
    }
  }
  .MuiDialogContent-root,
  .MuiDialogActions-root {
    padding: 0;
  }
  .img-container {
    width: 100%;
  }
}
