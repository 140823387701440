@import "../../scss/variables";
.open{
  background-color: $tag-green-faint;
  color: $tag-green;
}
.closed{
  background-color:$tag-red-faint;
  color: $tag-red;
}
.neutral{
  background-color: $gray-border ;
}
.type-detail{
  display: inline-block;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 1rem 2rem;
  width: 12rem;
  text-align: center;
}
.text-infos{
  color: $gray-icon;
}
.detail-info{
  border-right: 1px solid $gray-stepper-disabled;
}
.aside-bar-main{
  height: 350px;
  overflow-y: auto;
}
.image-cover{
  object-fit: cover;
}
.preview-image-container{
  width: 380px;
  height: 380px;
  margin: 0 auto;
}