@import "../../../scss/variables";

.filter-date-picker-wrapper {
  position: relative;

  .MuiFormControl-root .MuiTextField-root.custom-date-picker {
    background-color: $gray-mid !important;
    width: 100%;
    padding-inline-start: 1rem;
    padding-inline-end: 1.563rem;

    &:hover {
      background-color: $gray-mid;
    }

    &:focus::placeholder {
      color: $gray;
    }

    &.disbaled {
      background-color: $disbaled-input-color;
    }
  }

  input.MuiInputBase-input.MuiOutlinedInput-input {
    &::placeholder {
      font-family: "Roboto" !important;
      font-size: 1rem !important;
      color: $gray !important;
    }

    padding: 1.5rem 1rem 0.5rem 1rem !important;
    max-height: 1rem !important;
  }

  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
    margin: -4px !important;
  }

  label {
    top: -5px;

    &.MuiInputLabel-shrink {
      top: 13px !important;
    }

    &.Mui-focused {
      color: $filters-label;
    }
  }

  div.MuiInputBase-root.MuiOutlinedInput-root {
    background-color: $filters-background;
    border-radius: 0;
  }

  .close-btn {
    position: absolute;
    inset-inline-end: 1.2rem;

    .clear-icon {
      font-size: 1.3rem;
    }
  }
}

html[dir="rtl"] {
  .filter-date-picker-wrapper {
    label {
      right: 25px;

      &.MuiInputLabel-shrink {
        right: 10px !important;
      }
    }

    .MuiCalendarPicker-root {
      .MuiPickersCalendarHeader-labelContainer {
        margin-left: auto !important;
        margin-right: 0 !important;
      }

      .MuiPickersArrowSwitcher-root {
        direction: ltr !important;
      }
    }

    .close-btn {
      inset-inline-end: 1.8rem;
    }
  }
}

.susbend-by-time-modal+div.MuiPickersPopper-root {
  .MuiDayPicker-slideTransition {
    min-height: 12rem;
  }
}