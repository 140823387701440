@font-face {
  font-family: "Cairo-Light";
  src: url("../assets/fonts/Cairo-Light.eot");
  src: url("../assets/fonts/Cairo-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Cairo-Light.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Light.woff") format("woff"),
    url("../assets/fonts/Cairo-Light.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Light.svg#Cairo-Light") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Regular";
  src: url("../assets/fonts/Cairo-Regular.eot");
  src: url("../assets/fonts/Cairo-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Cairo-Regular.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Regular.woff") format("woff"),
    url("../assets/fonts/Cairo-Regular.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Regular.svg#Cairo-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Medium";
  src: url("../assets/fonts/Cairo-Medium.eot");
  src: url("../assets/fonts/Cairo-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Cairo-Medium.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Medium.woff") format("woff"),
    url("../assets/fonts/Cairo-Medium.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Medium.svg#Cairo-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Bold";
  src: url("../assets/fonts/Cairo-Bold.eot");
  src: url("../assets/fonts/Cairo-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Cairo-Bold.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Bold.woff") format("woff"),
    url("../assets/fonts/Cairo-Bold.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Bold.svg#Cairo-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-ExtraBoldItalic";
  src: url("../assets/fonts/Urbanist-ExtraBoldItalic.eot");
  src: url("../assets/fonts/Urbanist-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-ExtraBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-ExtraBoldItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-ExtraBoldItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-ExtraBoldItalic.svg#Urbanist-ExtraBoldItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-BoldItalic";
  src: url("../assets/fonts/Urbanist-BoldItalic.eot");
  src: url("../assets/fonts/Urbanist-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-BoldItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-BoldItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-BoldItalic.svg#Urbanist-BoldItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-SemiBoldItalic";
  src: url("../assets/fonts/Urbanist-SemiBoldItalic.eot");
  src: url("../assets/fonts/Urbanist-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-SemiBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-SemiBoldItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-SemiBoldItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-SemiBoldItalic.svg#Urbanist-SemiBoldItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-Italic";
  src: url("../assets/fonts/Urbanist-Italic.eot");
  src: url("../assets/fonts/Urbanist-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-Italic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-Italic.woff") format("woff"),
    url("../assets/fonts/Urbanist-Italic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-Italic.svg#Urbanist-Italic") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-ExtraLightItalic";
  src: url("../assets/fonts/Urbanist-ExtraLightItalic.eot");
  src: url("../assets/fonts/Urbanist-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-ExtraLightItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-ExtraLightItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-ExtraLightItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-ExtraLightItalic.svg#Urbanist-ExtraLightItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-BlackItalic";
  src: url("../assets/fonts/Urbanist-BlackItalic.eot");
  src: url("../assets/fonts/Urbanist-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-BlackItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-BlackItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-BlackItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-BlackItalic.svg#Urbanist-BlackItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-LightItalic";
  src: url("../assets/fonts/Urbanist-LightItalic.eot");
  src: url("../assets/fonts/Urbanist-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-LightItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-LightItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-LightItalic.svg#Urbanist-LightItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-MediumItalic";
  src: url("../assets/fonts/Urbanist-MediumItalic.eot");
  src: url("../assets/fonts/Urbanist-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-MediumItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-MediumItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-MediumItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-MediumItalic.svg#Urbanist-MediumItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-ThinItalic";
  src: url("../assets/fonts/Urbanist-ThinItalic.eot");
  src: url("../assets/fonts/Urbanist-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-ThinItalic.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-ThinItalic.woff") format("woff"),
    url("../assets/fonts/Urbanist-ThinItalic.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-ThinItalic.svg#Urbanist-ThinItalic")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-Medium";
  src: url("../assets/fonts/Urbanist-Medium.eot");
  src: url("../assets/fonts/Urbanist-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-Medium.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-Medium.woff") format("woff"),
    url("../assets/fonts/Urbanist-Medium.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-Medium.svg#Urbanist-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-Bold";
  src: url("../assets/fonts/Urbanist-Bold.eot");
  src: url("../assets/fonts/Urbanist-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-Bold.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-Bold.woff") format("woff"),
    url("../assets/fonts/Urbanist-Bold.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-Bold.svg#Urbanist-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-Regular";
  src: url("../assets/fonts/Urbanist-Regular.eot");
  src: url("../assets/fonts/Urbanist-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-Regular.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-Regular.woff") format("woff"),
    url("../assets/fonts/Urbanist-Regular.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-Regular.svg#Urbanist-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-ExtraBold";
  src: url("../assets/fonts/Urbanist-ExtraBold.eot");
  src: url("../assets/fonts/Urbanist-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Urbanist-ExtraBold.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-ExtraBold.svg#Urbanist-ExtraBold")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-SemiBold";
  src: url("../assets/fonts/Urbanist-SemiBold.eot");
  src: url("../assets/fonts/Urbanist-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-SemiBold.woff") format("woff"),
    url("../assets/fonts/Urbanist-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-SemiBold.svg#Urbanist-SemiBold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-Black";
  src: url("../assets/fonts/Urbanist-Black.eot");
  src: url("../assets/fonts/Urbanist-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-Black.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-Black.woff") format("woff"),
    url("../assets/fonts/Urbanist-Black.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-Black.svg#Urbanist-Black") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-Thin";
  src: url("../assets/fonts/Urbanist-Thin.eot");
  src: url("../assets/fonts/Urbanist-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-Thin.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-Thin.woff") format("woff"),
    url("../assets/fonts/Urbanist-Thin.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-Thin.svg#Urbanist-Thin") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-ExtraLight";
  src: url("../assets/fonts/Urbanist-ExtraLight.eot");
  src: url("../assets/fonts/Urbanist-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-ExtraLight.woff") format("woff"),
    url("../assets/fonts/Urbanist-ExtraLight.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-ExtraLight.svg#Urbanist-ExtraLight")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist-Light";
  src: url("../assets/fonts/Urbanist-Light.eot");
  src: url("../assets/fonts/Urbanist-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Urbanist-Light.woff2") format("woff2"),
    url("../assets/fonts/Urbanist-Light.woff") format("woff"),
    url("../assets/fonts/Urbanist-Light.ttf") format("truetype"),
    url("../assets/fonts/Urbanist-Light.svg#Urbanist-Light") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-BlackItalic.eot");
  src: url("../assets/fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-BlackItalic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-BlackItalic.woff") format("woff"),
    url("../assets/fonts/Roboto-BlackItalic.ttf") format("truetype"),
    url("../assets/fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Black.eot");
  src: url("../assets/fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Black.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Black.woff") format("woff"),
    url("../assets/fonts/Roboto-Black.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Black.svg#Roboto-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Bold.eot");
  src: url("../assets/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Bold.woff") format("woff"),
    url("../assets/fonts/Roboto-Bold.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-BoldItalic.eot");
  src: url("../assets/fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-BoldItalic.woff") format("woff"),
    url("../assets/fonts/Roboto-BoldItalic.ttf") format("truetype"),
    url("../assets/fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-MediumItalic.eot");
  src: url("../assets/fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-MediumItalic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-MediumItalic.woff") format("woff"),
    url("../assets/fonts/Roboto-MediumItalic.ttf") format("truetype"),
    url("../assets/fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Medium.eot");
  src: url("../assets/fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Medium.woff") format("woff"),
    url("../assets/fonts/Roboto-Medium.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.eot");
  src: url("../assets/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Regular.woff") format("woff"),
    url("../assets/fonts/Roboto-Regular.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Italic.eot");
  src: url("../assets/fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Italic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Italic.woff") format("woff"),
    url("../assets/fonts/Roboto-Italic.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-LightItalic.eot");
  src: url("../assets/fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-LightItalic.woff") format("woff"),
    url("../assets/fonts/Roboto-LightItalic.ttf") format("truetype"),
    url("../assets/fonts/Roboto-LightItalic.svg#Roboto-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Light.eot");
  src: url("../assets/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Light.woff") format("woff"),
    url("../assets/fonts/Roboto-Light.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-ThinItalic.eot");
  src: url("../assets/fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-ThinItalic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-ThinItalic.woff") format("woff"),
    url("../assets/fonts/Roboto-ThinItalic.ttf") format("truetype"),
    url("../assets/fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Thin.eot");
  src: url("../assets/fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Thin.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Thin.woff") format("woff"),
    url("../assets/fonts/Roboto-Thin.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
