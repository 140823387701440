@import "../../../scss/variables";

.forms-section-title-wrapper {
    display: flex;
    align-items: center;

    span {
        width: 13px;
        height: 25px;
        // Blue Shape Befor Title Needed to be fixed dimensions
        border-radius: 4px;
        background-color: $blue-title-mark;
        margin-inline-end: 1rem;
    }

    h2 {
        font-size: 1.4rem !important;
        color: $dark-gray;
        @media #{$media-sm} {
            font-size: 1.2rem !important; 
        }
    }
}