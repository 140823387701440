@import "../../../scss/variables";

.applicant-report-modal {
  .MuiFormLabel-root {
    text-align: start !important;
    margin-right: -20px;
  }
  .MuiDialogContent-root {
    padding: 0 !important;
    overflow-y: inherit;
    .date-picker-wrapper {
      .MuiFormControl-root input {
        padding: 0.754rem 1rem 0.754rem 0;
      }
    }
    .date-picker-label {
      color: $text-main-blue;
      font-weight: bold;
    }
    .check-in-date {
      color: $green !important;
      font-weight: bold;
    }
    .check-out-date {
      color: $red !important;
      font-weight: bold;
    }
  }
}
div.modal-container div.MuiPaper-root.MuiPaper-elevation {
  width: 55%;
  @media #{($media-mobile)} {
    width: 95%;
  }
  @media #{($media-desktop-max-lg)} {
    width: 98%;
  }
  h2.MuiTypography-h6 {
    padding: 0 !important;
    color: $dark-gray;
    font-size: 1.5rem;
  }
  .btns-container {
    .cancel-btn {
      border-color: $slate-grey;
      span {
        color: $slate-grey;
      }
    }
  }
}
.MuiDialogActions-root.MuiDialogActions-spacing.justify-content-center.applicant-actions-container {
  justify-content: end !important;
}
