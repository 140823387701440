@import "../../../../scss/variables";

.image-placeholder {
    background-size: cover;
    background-position: center;
    height: 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .placeholder-text{
        color: $white;
        position: relative;
        font-size: 1.5rem;
        max-width: 80%;
    }
}