@import "../../../scss/variables";

.event-details-page-container {
  .top-btns-container {
    .btn {
      @media #{$media-sm} {
        margin-top: 0.5rem;
        min-width: 6.34rem;
      }
    }

    @media #{$media-mobile} {
      margin-top: 1rem;
    }
  }

  .step-part-content-wrapper {
    background-color: $white;
    padding: 2rem;

    @media #{$media-sm} {
      padding: 2.625rem 1rem 2.5rem;

      .top-wrapper {
        flex-direction: column;

        button.add-btn {
          margin-top: 1rem;
        }
      }
    }
  }

  button.edit-btn {
    padding: 0.7rem 0.5rem;
    max-height: 2.3rem !important;
    color: $white;
    font-size: 1rem !important;
    font-weight: 600;
  }

  .view-image-mega-container {
    position: relative;
    min-width: 25rem;
    max-width: 34%;
    aspect-ratio: 2/1;
    margin-top: 0.5rem;

    @media #{$media-mobile-sm} {
      min-width: auto;
      max-width: 100%;
    }

    .blur-image {
      background-repeat: no-repeat;
      background-size: cover !important;
      background-position: center;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 4px;
      top: 4px;
      right: 4px;
      bottom: 4px;
      z-index: 1;
      filter: blur(4px);
    }
  }

  .min-height-map {
    min-height: 20rem;
  }

  .min-height-placeholder-map {
    min-height: 20rem;
  }

  .map-placeholder-container {
    max-width: 100%;
    aspect-ratio: unset;
  }

  .view-image-event {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
    position: relative;
  }

  .view-field-label {
    color: $gray-label-color;
    margin: 0.5rem 0;
  }

  .view-zones-image {
    min-height: 26rem !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .min-height-placeholder-zones-image {
    min-height: 26rem !important;
  }

  .view-applicants-btn-container {
    display: flex;
    justify-content: flex-end;

    button {
      background-color: transparent !important;
      outline: 0 !important;
      box-shadow: none !important;
      border: none;
      color: $text-main-blue !important;

      .MuiTouchRipple-root {
        display: none !important;
      }
    }
  }
}

.view-roles-tab {
  .show-time-margin {
    .margin-time {
      border-radius: 5px;
      padding: 0.74rem 0.5rem;
      box-shadow: 0px 0px 10px 4px rgba(191, 191, 191, 0.1);
      .clock-icon {
        color: $text-main-blue;
      }
      .time {
        color: $gray;
      }
    }
  }
  .margins-labels {
    color: $gray-label-color;
  }
  .margin-label-width {
    min-width: 18rem;
    max-width: 18rem;
  }
  .margin-minutes-width {
    min-width: 6rem;
    max-width: 6rem;
  }
}

.MuiFormHelperText-root {
  &.Mui-error {
    position: absolute;
    bottom: -25px;
    left: 0;
    font-size: 12px;
    margin: 0;
  }
}
.MuiDialogActions-root.MuiDialogActions-spacing.justify-content-center.applicant-actions-container {
  padding: 0;
  display: none;
}
.notification-message {
  .MuiPaper-root {
    padding: 0 !important;
    background-color: #448bca !important;
    .MuiSnackbarContent-message {
      padding: 10px 20px !important;
    }
  }
}
