@import "../../scss/variables";

.date-picker-wrapper {
  position: relative;

  .date-picker-label {
    font-family: "Roboto" !important;
    font-size: 1rem !important;
    color: $gray-label-color;
    margin: 0.938rem 0;
  }

  .custom-date-picker {
    &.MuiFormControl-root {
      background-color: $gray-mid;
      width: 100%;
      padding-inline-start: 1rem;
      padding-inline-end: 1.563rem;

      &:hover {
        background-color: $gray-mid;
      }

      &:focus::placeholder {
        color: $gray;
      }

      input {
        font-family: "Roboto" !important;
        font-size: 1rem;
      }
    }

    input.MuiInputBase-input {
      &::placeholder {
        font-family: "Roboto" !important;
        font-size: 1rem !important;
        color: $gray-placeholder-darker;
      }
    }

    input.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
      // background-color: $disbaled-input-color;
      -webkit-text-fill-color: $disabled-text;
    }
  }
}

html[dir="rtl"] {
  .MuiCalendarPicker-root {
    .MuiPickersCalendarHeader-labelContainer {
      margin-left: auto !important;
      margin-right: 0 !important;
    }
    .MuiCalendarPicker-viewTransitionContainer {
      direction: ltr !important;
    }
  }
}

.susbend-by-time-modal + div.MuiPickersPopper-root {
  .MuiDayPicker-slideTransition {
    min-height: 12rem;
  }
}
