@import "../../scss/variables";

.applicants-page-container {
  .MuiDataGrid-columnHeader:nth-child(1) {
    .MuiDataGrid-menuIcon {
      visibility: visible !important;
      width: auto !important;
    }
  }
}

.input-field {
  div,
  label,
  input,
  fieldset {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  input.MuiInputBase-input {
    max-height: 1rem;
    border: none !important;

    &::placeholder {
      color: $search-input-placeholder;
      padding-right: 20px !important;
    }
  }
}

.user-name {
  margin-left: 1rem;
}

html[dir="rtl"] {
  .applicants-list-container {
    .MuiFormLabel-root.MuiInputLabel-root {
      right: 1.8rem;
    }
  }
  .rating-container {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }

  .total-hours-container {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }

  .user-name {
    margin-right: 1rem;
  }

  .MuiDataGrid-cellContent {
    direction: rtl !important;
  }
}
.add-note-button{
  outline: none;
  border:none;
  background: #448bca;
  color: white;
  border-radius: 4px;
  padding: 10px;
  font-weight:bold;
  font-size: 12px;
  position: relative;
  z-index: 998 !important;
  width: 100%;
}
.delete-note-button{
  outline: none;
  font-weight:600;
  border:1px solid $tag-red;
  background: transparent;
  color: $tag-red;
  border-radius: 4px;
  padding: 6px;
  font-size: 12px;
  position: relative;
  z-index: 998 !important;
}
.edit-profile-button{
  outline: none;
  border:1px solid #448bca;
  color: #448bca;
  background: transparent;
  border-radius: 4px;
  padding: 10px 8px;
  font-weight:bold;
  font-size: 12px;
  position: relative;
  z-index: 998 !important;

}
.input-label-date{
  font-size: 14px !important;
  color: #333333 !important;
  margin: 0 !important;
}
.input-input-wrapper{
  padding: 0 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-bottom: 0 !important;
}
.MuiInputBase-input {
  margin-bottom:  0 !important;
  border: 0 !important;
  padding: 8px !important;
}

.input-input-wrapper > div{
  padding: 10px ;
}