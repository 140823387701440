@import "../../../../scss/variables";

.status-type {
  width: 7.9rem !important;
  height: 2rem !important;
  border-radius: 5px !important;
}

.assigned {
  background-color: $light-blue !important;
  color: $text-main-blue !important;
}

.not-assigned {
  background-color: $filters-background !important;
  color: $slate-grey !important;
}

.status-modal {
  .MuiPaper-elevation {
    padding: 1.8rem !important;
  }
}
.roles-label {
  color: $slate-grey;
}

.applicants-type-select {
  .MuiFormGroup-root {
    margin: 0 !important;
  }
}

.decreased-img-size {
  width: 25rem;
}

.export-note-button{
  outline: none;
  border: none;
  background-color: #448bca;
  color: #fff;
  padding: 10px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.note-cell{
  width: 7.5rem;
}
.data-cell{
  width: 8rem
}
.check-in-cell{
  width: 12rem
}