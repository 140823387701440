@import "../../scss/variables";

.snackbar-container {
  .snackbar.success {
    .MuiSnackbarContent-root {
      background-color: $sucess-background;
      color: $slate-grey;
    }
  }
  .snackbar.error {
    .MuiSnackbarContent-root {
      background-color: $error-background;
      color: $slate-grey;
    }
  }
  .MuiSnackbarContent-root {
    justify-content: center;
  }
  .alert_no_1 {
    bottom: 25px;
    @media #{$media-sm} {
      bottom: 50px;
    }
  }

  .alert_no_2 {
    bottom: 75px;
    @media #{$media-sm} {
      bottom: 100px;
    }
  }
  .alert_no_3 {
    bottom: 125px;
    @media #{$media-sm} {
      bottom: 150px;
    }
  }
  .alert_no_4 {
    bottom: 175px;
    @media #{$media-sm} {
      bottom: 200px;
    }
  }
  .alert_no_5 {
    bottom: 225px;
    @media #{$media-sm} {
      bottom: 250px;
    }
  }
}
