@import "../../scss/variables";

.status-type {
  width: 7.9rem !important;
  height: 2rem !important;
  border-radius: 5px !important;
}
.evaluation-status-container {
  width: 7rem !important;
  height: 2rem !important;
  border-radius: 5px !important;
  &.completed,
  &.done,
  &.submitted {
    background-color: $light-blue !important;
    color: $text-main-blue !important;
  }

  &.in-progress,
  &.pending {
    background-color: $filters-background !important;
    color: $slate-grey !important;
  }
}
.applicant-name {
  color: $dark-gray;
}
.rating-container {
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: #fecf6a;
  }
}

.divider-element {
  border: 1px solid #6b778c !important;
}
.evaluation-form-container {
  .comments {
    font-size: 1rem;
    color: $slate-grey !important;
  }
  .evaluations-skills-wrapper {
    .skill-title {
      font-size: 1rem;
      color: $slate-grey !important;
    }
    .skill-title {
      @media #{($media-sm)} {
        width: 100% !important;
        margin-block-start: 1rem;
      }
    }
    //change color ratings
    .rating-stars {
      .MuiRating-icon {
        padding-left: 0.2rem;
      }
      .MuiRating-iconFilled {
        color: #fecf6a !important;
      }
    }
  }
}
.rating-value {
  font-weight: 500;
}
.title-wrapper {
  display: flex;
  align-items: center;

  span {
    width: 13px;
    height: 25px;
    border-radius: 4px;
    background-color: $blue-title-mark;
    margin-inline-end: 1rem;
  }

  h2 {
    font-size: 1.37rem !important;
    color: $text-main-blue;
    @media #{$media-sm} {
      font-size: 1.2rem !important;
    }
  }
}

.evaluation-list-container {
  @media #{($media-mobile)} {
    .mobile-style {
      width: fit-content !important;
      white-space: nowrap;
    }
  }
}

.comments-rich-text {
  div.ql-editor {
    min-height: 12rem;
  }
}
// .MuiButton-outlinedSizeMedium.cancel-btn {
//   color: $red;
//   border-color: $red;
// }
