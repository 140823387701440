@import "../../../scss/variables";

.createEvent-page-container {
  .step-part-content-wrapper {
    background-color: $white;
    padding: 2rem;

    @media #{$media-sm} {
      padding: 2.625rem 1rem 2.5rem;
    }
  }

  .event-image-upload-initial-container {
    width: 100% !important;
    background-color: $gray-mid !important;
    aspect-ratio: 2/1;
  }

  div.event-image-upload-initial-container.zone-image {
    min-height: 18.75rem !important;
  }

  div.file-upload.event-image-upload-initial-container {
    background-color: transparent !important;
  }

  div.file-upload {
    span.btn-upload {
      background-color: transparent !important;
    }
  }

  .mege-upload-container {
    min-width: 25rem;
    max-width: 34%;

    @media #{$media-mobile-sm} {
      min-width: auto;
      max-width: 100%;
    }
  }

  .event-image-upload-container {
    z-index: 2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    aspect-ratio: 2/1;
    height: 100%;

    &.min-height-zone-image {
      min-height: 18.75rem !important;
    }
  }

  .min-height-image {
    min-height: 10.625rem;
  }

  .min-height-zone-image {
    min-height: 18.75rem !important;
  }

  .center-uplod-btn {
    background-color: $text-main-blue;
    padding: 0.32rem;
    font-size: 1rem !important;
    border-radius: 4px;
  }

  label.field-label {
    font-size: 1rem !important;
    color: $gray-label-color;
    margin: 0.938rem 0 !important;
  }
  .zones-container {
    .zone-label {
      margin: 0 0 0.938rem 0 !important;
    }
  }

  div.create-event-input {
    ::-webkit-input-placeholder {
      font-size: 1rem !important;
      color: $gray-placeholder-darker;
    }
  }

  .text-dark-gray {
    color: $dark-gray;
  }

  button.add-btn {
    padding: 0 0.504rem;
    max-height: 2rem !important;
    border-color: $text-dark;
    color: $text-dark;
    font-size: 1rem !important;
    font-weight: 600;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  button.continue-btn {
    padding: 0.32rem 0 !important;
    max-height: 3rem !important;

    &.Mui-disabled {
      span {
        font-size: 1rem;
      }
    }

    span {
      font-size: 1rem;
      color: $white;
    }

    &.save-publish {
      padding: 0.32rem 0.2rem !important;
    }
  }

  button.save-later-btn,
  button.cancel {
    padding: 0.26rem 1rem !important;
    border: 1.5px solid $text-dark;
    max-height: 3rem;

    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    span {
      font-size: 1rem;
      font-weight: 500;
      color: $text-dark;
      white-space: nowrap;
    }

    &.Mui-disabled {
      border: 1.5px solid $slate-grey;

      span {
        color: $slate-grey;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    @media #{$media-sm} {
      min-width: 10.315rem !important;
    }
  }

  @media #{$media-sm} {
    button.cancel {
      margin-top: 0.8rem;
    }
  }

  .min-height-map {
    min-height: 20rem;
  }

  .comments-rich-text {
    div.ql-editor {
      min-height: 12rem;
    }
  }

  @media #{$media-sm} {
    .buttom-btns-container {
      flex-direction: column;

      button.continue-btn {
        margin-top: 1rem;
      }
    }
  }
}
