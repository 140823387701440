/* ============= Colors ============= */
// Add the theme colors
$theme-colors: (
  // "primary": #171717,
  "main": #448bca,
  "dark": #000
);

// Add colors variables
// $primary: #171717 !default;
$white: #ffffff !default;
$dark-white: #fcfcfc !default;
$gray-mid: #fafafa !default;
$gray-light: #f6f6f6 !default;
$gray-border: #f4f4f4 !default;
$orange-unseen: #ff6a55 !default;
$search-input-placeholder: #1212124d !default;
$green: #5fd394 !default;
$red: #ff7070 !default;
$red-faint-bg: #FFF5F3 !default;
$sucess-background: #5fd39433 !default;
$error-background: #ff707033 !default;
$gray-light-border: #f4f4f480 !default;
$disbaled-input-color: #e0e0e0 !default;
$gray-stepper-disabled: #dfe1e6 !default;
$warning-light: #fff4e5 !default;
$blue-title-mark: #b1e5fc !default;
$light-blue: #b1e5fc80 !default;
$monthly-label-color: #cabdff !default;
$dimed-data: #c1bbbb !default;
$gray-label-color: #b0b9c8 !default;
$gray-placeholder-darker: #9e9e9e !default;
$gray: #888d94 !default;
$gray-icon: #6f767e !default;
$blue-btn-hover: #6195c3 !default;
$text-main-blue: #448bca !default;
$dark-gray: #4e5d78 !default;
$slate-grey: #6b778c !default;
$blue-accordion: #2699ff24 !default;
$light-gray: #f4f4f480 !default;
$border-blue: #1385c866 !default;
$light-blue-border: #448bca33 !default;
$alt-text-avatar: #42526e !default;
$filters-background: #091e420a !default;
$text-dark: #121212 !default;
$black: #000000 !default;
$border-color: #66666640 !default;
$filters-label: #00000099 !default;
$black-title: #212427 !default;
$warning: #663c00 !default;
$orange-unseen: #ff6a55 !default;
$white-transparent: #ffffff80 !default;
$disabled-text: #00000066 !default;
$table-hover-bg: #f0eded !default;
$rating-color: #ffc107 !default;
// Tags colors
$tag-grey-faint: #e1e1e1 !default;
$tag-grey: #6b778c !default;
$tag-green-faint: #c7fad8 !default;
$tag-green: #44c272 !default;
$tag-red-faint: #ffb2b2 !default;
$tag-red: #e44a4a !default;
$tag-orange-faint: #fff1e5 !default;
$tag-orange: #ff8b34 !default;
$tag-purple-faint: #e4caff !default;
$tag-purple: #a44eff !default;
$tag-blue-faint: #cbddff !default;
$tag-blue: #0b5cf9 !default;
$tag-light-blue-faint: #c3edff !default;
$tag-light-blue: #207dd0 !default;

$media-desktop-xlg: "only screen and (min-width : 1500px)";
$media-desktop-lg: "only screen and (max-width : 1300px)";
$media-desktop: "only screen and (max-width : 1200px)";
$media-desktop-max-lg: "only screen and (max-width : 991px)";
$media-mobile: "only screen and (max-width : 768px)";
$media-mobile-sm: "only screen and (max-width : 425px)";
$media-mobile-xs: "only screen and (max-width : 375px)";
$media-mobile-xxs: "only screen and (max-width : 320px)";
$media-md: "only screen and (min-width : 768px)";
$media-sm: "only screen and (max-width : 576px)";
