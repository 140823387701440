@import "../../scss/variables";
.MapToolTip {
  border-radius: 4px;
}
.polygon-image {
  border-radius: 4px;
}
.google-maps-container {
  .reset-map-wrapper {
    .map-search-input-wrapper {
      flex-grow: 1;
    }
    .reset-map-btn {
      background-color: $white;
      color: $black;
      &:hover {
        background-color: $white !important;
      }
    }
  }
}
