@import "../../../scss/_variables.scss";

.auth-container {
  min-height: 100vh;
  background-color: $white;
  @media #{$media-desktop-max-lg} {
    min-height: 100vh;
    height: 100%;
  }

  .langSwitch-container {
    border-bottom: 1px solid $border-color;
    position: relative;
    z-index: 2;
    background-color: $white;
  }

  .content-container {
    padding-top: 2rem;
    height: 80%;
    background-color: $white;

    @media #{$media-desktop-max-lg} {
      padding-top: 0;
      padding-bottom: 50px;
    }
  }

  .fields-container {
    width: 100%;

    @media #{$media-md} {
      width: 480px;
    }
  }

  .sideLeftLogo {
    display: none;

    @media #{$media-md} {
      display: block;
      position: fixed;
      left: 0;
      bottom: 0;
    }
  }

  .sideRightLogo {
    display: none;

    @media #{$media-md} {
      display: block;
      position: fixed;
      right: 0;
      top: 112px;
    }
  }

  @media #{$media-desktop-max-lg} {
    .logo-container {
      img {
        max-width: 75px;
      }
    }
  }
}

.layout-card {
  width: 100%;
  border-radius: 10px;
  background: white;
  z-index: 1;

  @media #{$media-desktop-max-lg} {
    width: 100%;
    padding: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  .subTitle {
    color: grey;
  }

  .title {
    letter-spacing: 1px;
  }
}

.component-section {
  width: 100%;

  @media #{($media-mobile)} {
    padding: 0 2rem;
  }

  img {
    @media #{($media-mobile)} {
      width: 70%;
    }

    @media #{($media-desktop-max-lg)} {
      width: 70%;
    }
  }
}

// Todo: Remove this style after adding logo
.Logo {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 10px;
  color: #003857;
}
