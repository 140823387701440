@import "../../scss/variables";

.time-picker-wrapper {
  position: relative;

  .time-picker-label {
    color: $gray-label-color;
    margin: 0.938rem 0;
  }

  .custom-time-picker {
    &.MuiFormControl-root {
      background-color: $gray-mid;
      width: 100%;
      padding-inline-end: 1.563rem;

      &:hover {
        background-color: $gray-mid;
      }

      &:focus::placeholder {
        color: $gray;
      }

      input {
        font-weight: 500 !important;
        font-size: 1.063rem;
      }
    }

    input.MuiInputBase-input {
      padding-top: 0.754rem;
      padding-bottom: 0.754rem;

      &::placeholder {
        font-size: 1rem !important;
        font-weight: 400;
        color: $gray-placeholder-darker;
      }
    }

    input.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: $disabled-text;
      font-weight: 400 !important;
    }
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    color: $gray-label-color !important;
    // min-height: 1.527rem;
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    top: -4px;
  }
}

html[dir="rtl"] {
  .MuiCalendarPicker-root {
    .MuiPickersCalendarHeader-labelContainer {
      margin-left: auto !important;
      margin-right: 0 !important;
    }
  }

  .time-picker-wrapper {
    .custom-time-picker {
      .MuiFormLabel-root.MuiInputLabel-root {
        left: auto;
        right: 1.25rem;
      }
    }
  }
}

.susbend-by-time-modal + div.MuiPickersPopper-root {
  .MuiDayPicker-slideTransition {
    min-height: 12rem;
  }
}

html[dir="rtl"] {
  .custom-time-picker {
    &.MuiFormControl-root {
      padding-inline-start: 1rem;
    }
  }
}
