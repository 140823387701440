@import "../../../scss/_variables.scss";

.reset-password-container {
    .reset-password-title {
        font-family: "Roboto" !important;
        font-weight: 500;
    }

    .reset-password-subtitle{
        font-family: "Roboto" !important;
        font-weight: 400;
        font-size: 20px;
        color: $slate-grey;
    }

    .box-contaienr {
        border: 1.2px solid $border-color;
      }
}


.orange-label {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
}