@import "../../../scss/variables";

.button-wrapper {
  button {
    width: 9.0625rem !important;
  }
}
.input-field {
  div,
  label,
  input,
  fieldset {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  input.MuiInputBase-input {
    max-height: 1rem;
    border: none !important;
    &::placeholder {
      color: $search-input-placeholder;
    }
  }
}

.filters-container {
  @media #{($media-md)} {
    .w-md-20 {
      width: 20% !important;
    }
  }
}

.icon-btn {
  border: 1.5px solid $slate-grey !important;
  border-radius: 5px !important;
  width: 20px;
  height: 20px;
}

.MuiList-dense {
  max-width: 100% !important;
}
.assign-modal {
  .MuiPaper-root {
    background-color: #f4f4f4 !important;
  }
}
.search-width {
  min-width: 0px !important;
}

.display-onhover {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.hover-action:hover {
  .display-onhover {
    visibility: visible;
    opacity: 1;
  }
}

.small-zone-applicants-empty-img {
  max-width: 30% !important;
}

html[dir="rtl"] {
  .MuiListItemSecondaryAction-root {
    left: 16px !important;
    right: 0px !important;
  }
  .text-right {
    text-align: right;
  }
}
