@import "../../scss/variables";

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 0.8rem !important;
  }
}
.mobile-screen {
  display: none;
}

@media #{($media-mobile)} {
  .mobile-screen {
    display: block;
  }
  .large-screen {
    display: none;
  }
}
