@import "../../../scss/variables";

.filter-wrapper {
  div.MuiInputBase-root.MuiOutlinedInput-root {
    background-color: $filters-background;
    border-radius: 0;
    padding: 0 !important;
  }
  input.MuiInputBase-input.MuiOutlinedInput-input {
    font-family: "Roboto" !important;
    font-size: 1rem;
    padding: 1.5rem 1rem 0.5rem 1rem !important;
    max-height: 1rem;
  }
  label {
    top: -5px;
    &.MuiInputLabel-shrink {
      top: 13px !important;
    }
    &.Mui-focused {
      color: $filters-label;
    }
  }
  fieldset {
    border: none !important;
  }
  .MuiChip-root {
    height: 25px;
    svg {
      font-size: 16px;
    }
  }
  &.multiple-filter-wrapper-padding {
    .MuiInputBase-root.MuiOutlinedInput-root {
      padding-top: 14px !important;
    }
  }
}

html[dir="rtl"] {
  .filter-wrapper {
    label {
      right: 25px;
      &.MuiInputLabel-shrink {
        right: 10px !important;
      }
    }
  }
}
