@import "../../scss/variables";

.requests-list-container {
  .input-field {
    div,
    label,
    input,
    fieldset {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    input.MuiInputBase-input {
      max-height: 1rem;
      border: none !important;

      &::placeholder {
        color: $search-input-placeholder;
      }
    }
  }

  .filters-container {
    div.date-picker-wrapper {
      div.MuiFormControl-root.MuiTextField-root {
        background-color: $filters-background;
        padding-inline-end: 0.8rem;

        div.MuiInputBase-root.MuiOutlinedInput-root {
          background-color: transparent;
        }
      }

      p {
        display: none !important;
      }

      input {
        padding-inline-start: 0;

        &::placeholder {
          color: $text-dark !important;
          visibility: visible;
          opacity: 1;
          font-size: 1rem !important;
          font-weight: 400 !important;
        }
      }
    }

    @media #{($media-md)} {
      .w-md-20 {
        width: 20% !important;
      }
    }
  }
  .date-cell {
    min-width: 7.5rem;
  }
  .name-cell,
  .event-cell {
    min-width: 10rem;
  }
  .edit-role-wrapper {
    width: max-content;
  }

  .status-container {
    width: 9rem !important;
    height: 2rem !important;
    border-radius: 5px !important;
    &.pending {
      background-color: $tag-grey-faint !important;
      color: $tag-grey !important;
    }
    &.approved {
      background-color: $tag-green-faint !important;
      color: $tag-green !important;
    }
    &.cancelled,
    &.auto_cancelled {
      background-color: $tag-red-faint !important;
      color: $tag-red !important;
    }
  }
}
.invite-applicants-list-container {
  thead {
    tr {
      th:nth-child(1) {
        width: 5% !important;
      }
    }
  }
  .select-column {
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }
  .rows-count-input {
    input {
      padding: 5px !important;
    }
  }
  .edit-role-wrapper {
    width: 70% !important;
  }
}
.rating-star-icon {
  color: $rating-color;
}

.bg-gray {
  background-color: $filters-background !important;
}

.text-gray {
  color: $gray !important;
}

.text-main {
  color: $slate-grey;
}

.role-input {
  input {
    background-color: transparent !important;
    padding: 0rem !important;
    cursor: pointer !important;

    &::placeholder {
      color: $dark-gray !important;
    }
  }
}

.chevron-down-position {
  inset-inline-end: 2.938rem !important;
}

.warning-modal {
  .MuiPaper-root {
    padding: 1rem !important;
  }
  .MuiAlert-root {
    padding: 0rem !important;
  }
  .MuiPaper-elevation {
    background-color: $warning-light !important;
  }
  .MuiTypography-h6 {
    color: $warning !important;
  }
  .MuiPaper-root.MuiPaper-elevation {
    width: 100% !important;
  }
}
.role-select-modal {
  .MuiDialogContent-root {
    padding: 1rem 0;
    text-align: start;
  }
}

.rows-count-selection {
  .rows-count-input {
    flex-direction: row !important;
    justify-content: end !important;
    align-items: center !important;
    .MuiFormLabel-root {
      margin: 0 0.5rem !important;
    }
    .MuiAutocomplete-root {
      width: 5rem;
    }
    .MuiAutocomplete-endAdornment {
      right: 5px !important;
    }
  }
}
.invite-btns-container {
  width: fit-content !important;
}

html[dir="rtl"] {
  .requests-list-container {
    .MuiFormLabel-root.MuiInputLabel-root {
      right: 1.8rem;
    }
  }
  .rows-count-selection {
    .rows-count-input {
      .MuiAutocomplete-endAdornment {
        left: 5px !important;
        right: auto !important;
      }
    }
  }
}

@media #{($media-mobile)} {
  .role-input {
    width: 130px !important;
  }
}

.delete-note-request{
  font-weight: 500;
  font-size: 14px;
  outline: none;
  border: 1px solid $tag-red;
  border-radius: 4px;
  padding: 8px;
  background: transparent;
  color: $tag-red;
}
