@import "../../scss/variables";

.MuiPaper-elevation.MuiAccordion-root {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 1.25rem !important;
  &.Mui-expanded {
    margin-bottom: 0 !important;
  }
}

.MuiAccordionSummary-root {
  background-color: $blue-accordion !important;
  min-height: 3.625rem !important;

  @media #{$media-sm} {
    flex-direction: column;
    padding: 0 0.8rem !important;
    align-items: flex-start !important;
  }

  p {
    color: $dark-gray;
    font-size: 1.1rem;
    font-family: "Urbanist-Bold" !important;
    pointer-events: auto;

    @media #{$media-sm} {
      font-size: 1.2rem;
    }
  }

  .MuiAccordionSummary-expandIconWrapper,
  .extra-function-btn {
    pointer-events: auto;
  }

  @media #{$media-sm} {
    .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      inset-inline-end: 0;
    }

    .extra-function-btn {
      position: absolute;
      inset-inline-end: 1rem;
    }
  }

  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
}

.MuiAccordionDetails-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
