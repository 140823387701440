@import "../../scss/_variables.scss";

.monthly-report-container {
  .multiple-role-filter-wrapper {
    width: 35%;
    @media #{$media-desktop} {
      width: 47%;
    }
    @media #{$media-mobile} {
      width: 50%;
    }
    @media #{$media-mobile-sm} {
      width: 100%;
    }
  }
}

.work-slots-list-container {
  .flagged-row {
    background-color: $red-faint-bg !important;
    td {
      color: $red !important;
    }
  }
}
