@import "./variables";

body {
  background-color: $gray-border;
}

.error-msg {
  color: red;
  margin: 5px 0;
}

hr {
  background-color: grey;
  margin: 20px 0 12px 0;
}

.hint {
  color: grey;
}

.w-max-content {
  width: max-content;
}

.h-max-content {
  height: max-content;
}

.wrapper-bg {
  background-color: white;
  padding: 40px 30px;
  border-radius: 16px;
}

.error-border {
  border: 2px solid red !important;
}

.err {
  color: red !important;
}

.err-asterisk {
  color: red;
}

.opacity_7 {
  opacity: 0.7;
}

.opacity-6 {
  opacity: 0.6;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.content-wrapper {
  background-color: white;
  margin: 2.071rem;
}

.text-underline {
  text-decoration: underline !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dimed-text {
  color: $dimed-data !important;
}

.black-title {
  color: $black-title !important;
}

.capitalize {
  text-transform: capitalize;
}

.page-title-margin {
  margin: 1.5rem 0;
}
.word-break {
  word-break: break-word;
}
.row-hover {
  &:hover {
    cursor: pointer;
    background-color: $table-hover-bg !important;
  }
}
// To override browser autocomplete inputs styling
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: none !important;
}

input:-webkit-autofill {
  -webkit-border-radius: 0 !important;
}

@media #{($media-mobile)} {
  .mobile-style {
    width: fit-content !important;
    white-space: nowrap;
  }
}
