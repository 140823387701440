@import "../../scss/variables";

.btn {
  text-transform: none !important;
  box-shadow: none !important;
  border-radius: 4px;

  &.MuiButton-contained,
  &.MuiButton-contained:hover,
  &.MuiButton-contained:active,
  &.MuiButton-contained:focus-visible {
    color: $white !important;
    background-color: $text-main-blue !important;
    &.Mui-disabled {
      color: $white !important;
      background-color: $text-main-blue !important;
      opacity: 0.6;
    }
  }
  &.MuiButton-outlined,
  &.MuiButton-outlined:hover,
  &.MuiButton-outlined:active,
  &.MuiButton-outlined:focus-visible {
    color: $text-dark;
    border-color: $text-dark;
    background-color: transparent !important;
    &.Mui-disabled {
      color: $text-dark;
      border-color: $text-dark;
      background-color: transparent !important;
      opacity: 0.6;
    }
  }
  &.btn-negative,
  &.btn-negative:hover,
  &.btn-negative:active,
  &.btn-negative:focus-visible {
    color: $red !important;
    border-color: $red !important;
    background-color: transparent !important;
    &.Mui-disabled {
      color: $red !important;
      border-color: $red !important;
      background-color: transparent !important;
      opacity: 0.6;
    }
  }
  &.btn-positive,
  &.btn-positive:hover,
  &.btn-positive:active,
  &.btn-positive:focus-visible {
    color: $text-main-blue;
    border-color: $text-main-blue;
    background-color: transparent !important;
    &.Mui-disabled {
      color: $text-main-blue;
      border-color: $text-main-blue;
      background-color: transparent !important;
      opacity: 0.6;
    }
  }
  &.btn-block {
    width: 100%;
  }
  &.btn-small {
    padding: 0.3rem 0.7rem !important;
  }
}
