html,
body {
  font-size: 14px; //Set REM Base
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;
}

.font-cairo-regular {
  font-family: "Cairo-Regular" !important;
}

.font-cairo-medium {
  font-family: "Cairo-Medium" !important;
}

.font-cairo-semibold {
  font-family: "Cairo-Semibold" !important;
}

.font-cairo-bold {
  font-family: "Cairo-Bold" !important;
}

.font-regular {
  font-family: "Roboto" !important;
  font-weight: normal;
}

.font-medium {
  font-family: "Roboto" !important;
  font-weight: 500;
}

.font-bold {
  font-family: "Roboto" !important;
  font-weight: bold;
}

.font-light {
  font-family: "Roboto" !important;
  font-weight: 300;
}

.font-italic {
  font-family: "Roboto" !important;
  font-style: italic;
}

.font-urbanist-medium {
  font-family: "Urbanist-Medium" !important;
}

.font-urbanist-bold {
  font-family: "Urbanist-Bold" !important;
}

.font-urbanist-semibold {
  font-family: "Urbanist-SemiBold" !important;
}

.font-urbanist-regular {
  font-family: "Urbanist-Regular" !important;
}

.text-dark-primary {
  color: $text-dark !important;
}
.text-primary-blue {
  color: $text-main-blue;
}
.text-grey {
  color: $gray-placeholder-darker;
}
.text-light-grey {
  color: $gray-label-color;
}
.text-warning {
  color: $orange-unseen;
}
