@import "../..//scss/variables";

.avatars-container {
    .MuiAvatar-root.MuiAvatar-circular {
        max-width: 2rem;
        max-height: 2rem;

        &.MuiAvatar-colorDefault {
            background-color: $gray-stepper-disabled;
            font-size: 0.75rem;
            font-weight: 700;
            color: $alt-text-avatar;
        }
    }
}