@import "../../../../scss/variables";

button.add-slot-btn.MuiButton-root {
  max-width: 2.2rem;
  height: 2.2rem;
  border-radius: 100%;
  min-width: auto;
  padding: 0;
  background-color: $text-main-blue !important;
  border: none;

  &:hover {
    background-color: $text-main-blue !important;
  }
}

.remove-touched-label {
  label.MuiInputLabel-shrink {
    display: none !important;
  }
}
.slots-list {
  .time-slots-label {
    color: $gray-label-color;
    margin: 0.938rem 0;
  }
  .time-picker-label {
    margin: 0 !important;
  }
}

.roles-container {
  .show-time-margin {
    background-color: $gray-mid;
    border-radius: 5px;
    padding: 0.74rem 0.5rem;
    .clock-icon {
      color: $text-main-blue;
    }
    .time {
      color: $gray;
    }
  }
  .margins-labels {
    color: $gray-label-color;
  }
}
