@import "../../scss/variables";

.view-input-data-wrapper {
    .view-field-label {
        color: $gray-label-color;
        margin: 0.3rem 0 !important;

        @media #{$media-sm} {
            min-width: 3.42rem;
        }
    }

    .view-input-content-wrapper {
        color: $dark-gray;
        font-weight: 600;
    }

    .view-input-content-placeholder {
        color: $dimed-data !important;
    }

    @media #{$media-sm} {
        .custom-dots {
            margin-inline-start: 0.9rem !important;
        }
    }
}