@import "../../../scss/_variables.scss";

.reset-password-page {
  background-color: #ffffff;
  padding: 2rem;
  .orange-label {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }
  .MuiIconButton-edgeEnd {
    margin-right: unset !important;
  }
  button.continue-btn,
  button.cancel-btn {
    max-width: 5.1875rem;
    max-height: 3rem !important;
    &.Mui-disabled {
      span {
        color: $text-dark;
        font-size: 1rem;
      }
    }
    span {
      font-size: 1rem;
    }
  }
}
