@import "../../scss/variables";

.promotion-requests-list-container {
  .status-container {
    width: 7rem !important;
    height: 2rem !important;
    border-radius: 5px !important;
    &.pending {
      background-color: $tag-grey-faint;
      color: $tag-grey !important;
    }
    &.approved {
      background-color: $tag-green-faint;
      color: $tag-green !important;
    }
    &.rejected {
      background-color: $tag-red-faint;
      color: $tag-red !important;
    }
  }
  .date-cell,
  .name-cell {
    min-width: 10rem;
  }
}
@media #{($media-md)} {
  .w-md-50 {
    width: 50% !important;
  }
}

html[dir="rtl"] {
  .MuiFormLabel-root.MuiInputLabel-root {
    right: 1.8rem;
  }
}
