@import "../../scss/variables";

div.stepper-inner-container {
    &.pointer {
        cursor: pointer !important;

        .MuiStepLabel-root.Mui-disabled {
            cursor: pointer !important;
        }
    }

    span.MuiStepLabel-label {
        font-family: "Roboto" !important;
        font-size: 0.938rem !important;
        color: $slate-grey;

        &.Mui-completed,
        &.Mui-active {
            font-weight: 700 !important;
            color: $text-main-blue;
        }
    }

    span.MuiStepLabel-iconContainer {
        div.icon-container {
            z-index: 1;
            color: $white;
            padding: 1rem;
            display: flex;
            border: 6px solid $gray-stepper-disabled;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
        }

        &.Mui-active,
        &.Mui-completed {
            div.icon-container {
                background-color: $text-main-blue;
                border: 5px solid $text-main-blue;
                padding: 0;
            }
        }
    }

    .custom-connector {
        top: 1.4rem;


        &.connector-ar {
            right: calc(-50% + 20px) !important;
            left: calc(50% + 20px) !important;
        }

        .MuiStepConnector-line {
            border-radius: 1px;
            height: 3px;
            background-color: $gray-stepper-disabled;
            border: 0;
        }

        &.Mui-active,
        &.Mui-completed {
            .MuiStepConnector-line {
                background-color: $text-main-blue;
            }
        }
    }
}