@import "../../scss/variables";

.tabs-container {
  div.MuiTabs-flexContainer {
    border-bottom: 3px solid $light-blue-border;
    width: max-content;
  }
  button.MuiTab-root {
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: capitalize;
    margin: 0rem;
    margin-bottom: -3px;
    padding: 0.5rem 0.75rem;

    &.Mui-selected {
      font-weight: 500;
      color: $text-main-blue;
      border-bottom: 3px solid $text-main-blue;
    }
  }

  span.MuiTabs-indicator {
    display: none !important;
  }
  .MuiTabs-scroller.MuiTabs-fixed {
    overflow-x: auto !important;
  }
  .MuiTabs-scroller.MuiTabs-fixed::-webkit-scrollbar {
    height: 0px !important;
  }
}
