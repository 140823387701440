@import "../../../scss/variables";

.side-menu-container {
  background-color: $dark-white;
  height: 100vh;
  position: relative;
  padding: 0 1rem;
  .side-menu-list {
    overflow-y: scroll;
    max-height: calc(100vh - 9rem);
  }

  div,
  span,
  a {
    font-family: "Roboto" !important;
    font-weight: 600 !important;
    font-size: 1rem;
    color: $slate-grey;
  }

  .MuiListItem-root {
    color: grey !important;
  }

  .MuiListItem-root:hover {
    background-color: transparent !important;
    color: darkgrey !important;
    border-radius: 10px;
    color: $text-main-blue !important;

    div,
    span,
    a {
      background-color: transparent !important;
      color: $text-main-blue !important;
    }
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover,
  .MuiListItem-button:hover {
    background-color: transparent !important;

    div {
      background-color: transparent !important;
    }

    opacity: 1;
    border-radius: 10px;

    span {
      color: $text-main-blue !important;
    }
  }

  .MuiSvgIcon-root {
    color: grey;
  }

  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  *::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  *::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 20px;
  }

  *::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
  }

  .list-item-label span {
    white-space: pre-wrap;
  }

  .list-item-wrapper {
    &.closed-menu {
      padding: 0.5rem 0.3rem;
    }
  }

  .child-sub-list {
    padding-left: 2rem;

    &.closed-menu {
      padding-left: 0;
    }

    .list-item-wrapper {
      padding: 0.5rem 0 0.5rem 1rem;

      &.closed-menu {
        padding: 0;
      }
    }
  }
  .logo {
    margin-top: 1rem;
    width: 10rem;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
  }

  @media #{$media-md} {
    button.side-menu-toggle-icon {
      display: none !important;
    }
  }
}
@media #{$media-desktop} {
  .side-menu-container {
    padding: 0 !important;
  }
}

html[dir="rtl"] {
  .side-menu-container {
    .list-item-wrapper {
      &.closed-menu {
        padding: 0.5rem 0.3rem;
      }
    }

    .child-sub-list {
      padding-left: 0;
      padding-right: 2rem;

      &.closed-menu {
        padding-right: 0;
      }

      .list-item-wrapper {
        padding: 1rem 1rem 1rem 0;

        &.closed-menu {
          padding: 0;
        }
      }
    }
  }
}
