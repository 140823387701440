@import "../../../scss/variables";

.nav-bar.MuiAppBar-root {
  box-shadow: none !important;
  background-color: $dark-white !important;
  padding: 0;
  max-height: 60px;
  .MuiToolbar-root {
    min-height: 60px !important;
  }
  .notification-icon-wrapper {
    .notifications-count {
      background-color: $text-main-blue;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      right: 0;
    }
  }

  .page-title {
    @media #{$media-desktop-max-lg} {
      font-size: 1.7rem !important;
    }

    @media #{$media-mobile} {
      font-size: 1.5rem !important;
    }

    @media #{$media-mobile-sm} {
      font-size: 1.3rem !important;
    }
  }

  .create-event-btn-container {
    button.create-event-btn {
      border-radius: 0 !important;
      font-family: "Roboto" !important;
      font-size: 0.85rem;
    }
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  @media #{$media-sm} {
    .nav-items-container {
      width: 100%;
    }
    .nav-icons-container {
      width: 40%;
    }
  }
}
@media #{$media-desktop} {
  .nav-bar.MuiAppBar-root {
    .MuiToolbar-root {
      min-height: 50px !important;
    }
  }
}

html[dir="rtl"] {
  header.nav-bar {
    right: auto;
    left: 0;
    margin-left: 0;

    .mobile-toggle-icon {
      margin-left: 0rem;
    }
  }
}

.adorment-notifaction {
  color: $text-main-blue;
  .seen-adorment {
    width: 1rem;
  }
}
.see-all-btn {
  color: $text-main-blue;
  font-weight: bold;
}
