@import "../../scss/variables";

.btn-upload {
  background-color: $gray-mid !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.logoInput {
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
}

label {
  span[role="button"] {
    min-width: 6.875rem;
    min-height: 6.25rem;
    width: 100%;
  }

  span.btn-banner {
    @media #{$media-mobile} {
      min-width: 100%;
    }
  }

  .logoInput {
    width: 100%;
  }

  span[role="button"]:hover {
    background-color: $gray-mid !important;
  }

  &.grow-child-panner {
    span {
      flex-grow: 0.5;
    }
  }
}

.field-label {
  color: $gray-label-color;
  margin: 0.938rem 0;
}

.pdf-upload-container {
  min-height: 14rem;
  width: 15rem;
  border: 1px solid $border-blue;

  span {
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.uploaded-image-container {
  width: 100%;
  margin-bottom: 1rem;
}

.ovelay {
  position: absolute;
  top: 0.6rem;
  inset-inline-end: 0.6rem;
  z-index: 5;
  background-color: $white-transparent;
  border-radius: 100%;
}

.min-height-zone-image {
  .ovelay {
    top: -0.9rem;
  }

  .uploaded-image-container {
    min-height: 19rem;
  }
}

.ovelay.pdf-remove {
  top: 0.8rem;
  inset-inline-end: auto;
  inset-inline-start: 13rem;
}

.mega-uploaded-image-container {
  margin-left: auto;
  margin-right: auto;
}

.blur-image {
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 4px;
  top: 4px;
  right: 4px;
  bottom: 4px;
  z-index: 1;
  filter: blur(4px);
}