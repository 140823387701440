@import "../../scss/variables";

.events-list-container {
  .type-container {
    display: inline-block;
    text-transform: capitalize;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: 5.5rem;
    text-align: center;
    &.daily {
      background-color: $tag-light-blue-faint;
      color: $tag-light-blue;
    }
    &.monthly {
      background-color: $tag-purple-faint;
      color: $tag-purple;
    }
  }
  .publish-mode-cell {
    min-width: 10rem;
  }

  button.assign-pm-btn {
    max-height: 2rem;
    max-width: 5.68rem;
  }

  .filters-container {
    div.date-picker-wrapper {
      div.MuiFormControl-root.MuiTextField-root {
        background-color: $filters-background;
        padding-inline-end: 0.8rem;

        div.MuiInputBase-root.MuiOutlinedInput-root {
          background-color: transparent;
        }
      }

      p {
        display: none !important;
      }

      input {
        padding-inline-start: 0;

        &::placeholder {
          color: $text-dark !important;
          visibility: visible;
          opacity: 1;
          font-size: 1rem !important;
          font-weight: 400 !important;
        }
      }
    }

    @media #{($media-md)} {
      .w-md-23 {
        width: 23%;
      }

      .w-md-50 {
        width: 50%;
      }
    }
  }
}

@media #{($media-md)} {
  .w-md-50 {
    width: 50% !important;
  }
}

html[dir="rtl"] {
  .events-list-container {
    .type-container {
      &.monthly {
        padding: 0.4rem 0.9rem;
      }
    }
  }
}
