@import "../../../scss/variables";

.search-input-wrapper {
  fieldset {
    border: none !important;
  }
  .MuiInputBase-root {
    background-color: $white !important;
    border-radius: 0px !important;

    input.MuiInputBase-input {
      padding: 1rem 1rem 1rem 0 !important;
      max-height: 1rem;
      border: none !important;

      &::placeholder {
        color: $search-input-placeholder;
      }
    }

    input.MuiInputBase-input::placeholder {
      color: $search-input-placeholder !important;
      font-family: "Roboto" !important;
    }

    .MuiFilledInput-root:hover {
      opacity: 0.7;
    }

    .MuiSvgIcon-root {
      color: $search-input-placeholder !important;
    }
  }

  min-width: 25rem;
  max-width: 25rem;

  @media #{$media-mobile-sm} {
    min-width: 19rem;
  }
  @media #{$media-mobile-xs} {
    min-width: 17rem;
  }
  @media #{$media-mobile-xxs} {
    min-width: 10rem;
  }
}

html[dir="rtl"] {
  .MuiFormLabel-root.MuiInputLabel-root {
    left: auto;
  }

  div.MuiAutocomplete-endAdornment {
    right: auto !important;
    left: 1.2rem;
  }

  .pagination-wrapper {
    .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext {
      transform: scaleX(-1);
    }
  }
}
