@import "../../../scss/_variables.scss";

.change-password-page {
    .orange-label {
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
    }
    .MuiIconButton-edgeEnd {
        margin-right: unset !important;
      }
}
