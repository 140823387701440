@import "../../scss/variables";

div.modal-container {
  div.MuiPaper-root.MuiPaper-elevation {
    border-radius: 5 !important;
    margin: 0;
    width: auto;
    background-color: $gray-border;
    padding: 2rem;
  }

  h2.MuiTypography-h6 {
    padding: 5px 10px 0px !important;
  }

  button.cancel-btn {
    padding: 0 0.6rem;
    border: 1.5px solid $red;
    max-height: 3rem;

    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    span {
      font-size: 1rem;
      color: $red;
      white-space: nowrap;
    }
  }

  button.confirm-btn {
    padding: 0.322rem 0.35rem !important;
    max-height: 3rem !important;

    span {
      font-size: 1rem;
      color: $white;
    }
  }
  p {
    font-size: 1.2rem;
    color: $dark-gray;
  }

  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  *::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  *::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 20px;
  }

  *::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
  }
}
