@import "../../scss/variables";
.form-container {
  background-color: #f4f4f4;
}

.form-group {
  text-align: left;
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }

  textarea,
  select,
  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #448bca;
      outline: none;
    }
  }
}
.action-group{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.add-note-btn {
  background-color: #448bca;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-note-btn {
  background-color: #8e918f;
  color: #fff;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.select-mui {
  padding: 3px;

}
.select-mui:hover{
  outline: none;
}

.delete-item {
  background: transparent;
  color: $tag-red;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  border: 1px solid $tag-red;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  z-index: 9999;
}

.btn-r {
  right: 10px
}

.btn-l {
  left: 10px
}

.error-text {
  font-size: 12px;
  color: $tag-red;
  font-style: italic;
}

.custom-scroll {
  max-height: 300px !important;
  overflow-y: auto;
  scrollbar-width: thin !important;
  scrollbar-color: $gray-placeholder-darker transparent !important;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px !important;
  }
}
